import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Sku.module.scss'
import { useNavigate, useParams } from 'react-router'
import SkuForm from './sku-form/SkuForm'
import ChildSkuForm from './child-sku-form/ChildSkuForm'
import useI18n from 'common-hooks/useI18n'
import ReadOnlySku from './read-only-sku/ReadOnlySku'
import deliveryPromiseCalculator from 'pages/delivery-promise-calculator/delivery-promise-calculator'
import { calculateDeliveryDate } from 'app/utils'
import PrescriptionPrintPreview from 'pages/orders/prescription-print-preview/PrescriptionPrintPreview'
import PopUpDialog from 'components/pop-up/PopUp'
import Portal from 'components/portal/Portal'
import PrintIcon from './print-icon.png'

const SKU_STATUS = [];
const ARROW_DOWN = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.25977 0.779297L6.24023 5.75977L11.2207 0.779299" stroke="#31395F" strokeOpacity="0.5" strokeWidth="1.40625" strokeLinecap="round" strokeLinejoin="round" /> </svg>
const ARROW_UP = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10.7402 6.2207L5.75977 1.24023L0.779297 6.2207" stroke="#31395F" strokeOpacity="0.5" strokeWidth="1.40625" strokeLinecap="round" strokeLinejoin="round" /> </svg>
const HAS_PARENT_SKUS = ['fu00000-aop-eyewa-care', 'fu00000-aop-prescription-lens-conversion'];

function Sku({ name, category, status, sku, id: idAsNumber, frame, noseBridge, lensWidth, power, onUpdate, children: childProducts, childProducts: children, parentProducts, childItemId, parentItemId, loading, email, externalPrescription, prescriptionDoctorName, orderId, orderDate, phone, customerId, customerName, country, parentSku }) {
  const { i18n } = useI18n();
  const navigate = useNavigate();
  const id = useMemo(() => `${idAsNumber}`, [idAsNumber]);
  const { orderid, productid } = useParams();
  const isSelected = useMemo(() => productid === id, [productid, id]);
  const isNotEG = category.toLowerCase() !== 'eg';
  const isNotEditableStatus = !SKU_STATUS.includes(status?.toUpperCase?.());
  const hidePrescription = useMemo(() => isNotEG, [status, category]);
  const hasParent = useMemo(() => HAS_PARENT_SKUS.includes(sku.toLowerCase()), [sku]);
  const containerRef = useRef(null)
  const dialogRef = useRef(null);
  const [printPreviewData, setPrintPreviewData] = useState(null);

  const handleClick = () => {
    const endpoint = isSelected ? '' : `/sku/${id}`;
    navigate(`/orders/${orderid}${endpoint}`);
  }

  const commonFormProps = {
    onSubmit: onUpdate,
    loading,
    sku,
    email,
    hasParent,
    parentProducts,
    defaultParent: parentItemId,
    children,
    material: frame?.material,
    type: frame?.type,
    width: noseBridge,
    eyeSize: lensWidth,
    id: idAsNumber,
    prescriptionDoctorName,
    externalPrescription,
    phone,
    customerId,
  }

  let content = <SkuForm {...power} {...commonFormProps} orderId={orderId} />;

  if (hidePrescription) {
    content = <ReadOnlySku power={power} childItemId={childItemId} parentItemId={parentItemId} children={children} />
  } else if (childProducts.length && childItemId) {
    content = <ChildSkuForm {...commonFormProps} {...power} childProducts={childProducts} defaultChild={childItemId} parentItemId={parentItemId} orderId={orderId} />
  }

  let promise = ''
  promise = useMemo(() => {
    if (!hidePrescription) {
      return deliveryPromiseCalculator({
        lensType: power.lensType,
        addon: power.addOn1 || power.addOn2 || power.addOn3 || power.addOn5 || power.addOn6 || power.addOn7,
        color: power.addOnColor,
        frameType: frame.type,
        left: power.left,
        right: power.right

      })
    }
  }, [frame, power, hidePrescription])

  let deliveryDate = ''
  if (promise) {
    deliveryDate = calculateDeliveryDate(promise, orderDate)
  }

  const handleDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (dialogRef.current) {
      dialogRef.current.showModal();
    }
    setPrintPreviewData(JSON.parse(JSON.stringify({ ...commonFormProps, ...power, orderId })))
  }

  const renderPrintPreview = () => {
    if (printPreviewData) {
      return (
        <PrescriptionPrintPreview {...printPreviewData}
          country={country}
          containerRef={containerRef}
          dialogRef={dialogRef}
          customerName={customerName}
          customerId={customerId}
          customerPhone={phone}
        />
      )
    }
  }

  return (
    <>
      <Portal containerRef={containerRef}>
        <PopUpDialog ref={dialogRef} title="Print Preview" showCloseButton backdropClose dialogClassname={styles.dialogClassname} >
          <div>
            {renderPrintPreview()}
          </div>
        </PopUpDialog>
      </Portal>
      <div className={cx(styles.product)} onClick={handleClick}>
        <div className={cx(styles.col)}>
          <div className={cx(styles.info)}>
            <h3 className={cx(styles.name)}>
              {name}
              {parentSku && <span className={styles.parentSku}>{` | (${parentSku})`}</span>}
            </h3>
            {promise && <span className={cx(styles['delivery-promise'])}>
              <span>{'Delivery promise - '}</span>
              <span>{deliveryDate}</span>
            </span>}
            {!hidePrescription && <span className={styles.printButton} onClick={handleDialog}>
              <img src={PrintIcon} alt='print-icon' className={styles.printIcon} />
              <span>{'Print Prescription'}</span>
            </span>}
          </div>
          <p className={cx(styles.sku)}>{sku}</p>
          {hidePrescription && <p className={cx(styles.message)}>{i18n('Prescription cannot be added to this product')}</p>}
        </div>
        {!hidePrescription && (isSelected ? ARROW_UP : ARROW_DOWN)}
      </div>
      {!hidePrescription &&
        <div className={cx(styles.form, { [styles['selected']]: isSelected })}>
          {content}
        </div>
      }
    </>
  )
}

Sku.propTypes = {}

export default Sku
