import React, { useEffect, useMemo, useState } from 'react'
import { FormSectionTitle, Input, Button, SimpleSelect, Checkbox, UploadPrescription } from 'components'
import { useNavigate, useParams } from 'react-router'
import useI18n from 'common-hooks/useI18n';
import { useAssignWarehouseMutation, useChangePasswordAdminMutation, useCreateUserMutation, useLazyUsersQuery, useUpdateUserMutation, useUsersQuery, useWarehouseSetupQuery, useWarehousesQuery } from 'app/service';
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import cx from 'classnames'
import styles from '../Users.module.scss'
import MultiSelect from 'components/form-elements/MultiSelect';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/login/loginSlice';
import { ROLE_TYPES } from '../Roles';
import ChangePasswordModal from '../ChangePasswordModal';

const CROSS_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"> <path d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z" fill="#E96C5D" /> </svg>

function EditUser({ fieldExecutiveForm = false }) {
  const navigate = useNavigate();
  const { i18n } = useI18n();
  const { id } = useParams();
  const [createUser, { isLoading: createUserPending }] = useCreateUserMutation();
  const { data: usersData, isLoading: isUserLoading } = useUsersQuery();
  const { data: configData = [] } = useWarehouseSetupQuery();
  const [fetchUsers] = useLazyUsersQuery();
  const { data: warehousesData, isLoading: isWarehouseLoading } = useWarehousesQuery();
  const [assignWarehouse] = useAssignWarehouseMutation();
  const [updateUser, { isLoading: updateUserPending }] = useUpdateUserMutation();
  const [changePasswordAdmin] = useChangePasswordAdminMutation();
  const [loading, setLoading] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [isImageUploading, setIsImagesUploading] = useState(false);
  const { roleList } = useSelector(selectUser);
  const loggedInUserRole = roleList?.[0]?.roleName
  const isCreateUserFlow = !id

  const user = useMemo(() => usersData?.find(usr => `${usr.id}` === id) || {}, [usersData, id]);

  const methods = useForm({
    mode: 'all',
    defaultValues: { ...user, prescriptionUrl: user?.imageUrl }
  });

  const userGroup = useWatch({ control: methods.control, name: 'groupName' });
  const selectedUserRoleId = useWatch({ control: methods.control, name: 'roleId' });

  const getWarehouseOptions = useMemo(() => {
    if (isWarehouseLoading) return [{ label: 'Select', value: '' }];
    return warehousesData?.map(warehouse => ({ label: warehouse.warehouseName, value: warehouse.id })).filter(warehouse => !selectedWarehouses?.find(x => x.value === warehouse.value)) || [];
  }, [warehousesData, isWarehouseLoading, selectedWarehouses])

  const uploadPrescription = async (data) => {
    setIsImagesUploading(true);

    if (!data.prescriptionImageUpload) {
      return Promise.resolve('')
    };

    const body = new FormData();
    body.append("file", data.prescriptionImageUpload);

    const resp = await fetch(`${`${process.env.REACT_APP_API_BASE_URL}/api/v1/customer-prescription/upload-prescription?file`}`, {
      method: 'POST',
      body,
    });

    return resp.json();
  }

  const _handleSubmit = async (formData, e) => {
    try {
      const { url: prescriptionUrl } = await uploadPrescription(formData);
      if (prescriptionUrl) {
        formData.imageUrl = prescriptionUrl
      }
      setLoading(true);
      const warehouseIds = formData.warehouses.map(warehouse => warehouse.value).join(',');
      if (isCreateUserFlow) {
        Reflect.deleteProperty(formData, 'assign-all-stores')
        Reflect.deleteProperty(formData, 'warehouses')
        Reflect.deleteProperty(formData, 'prescriptionImageUpload')
        await createUser({ data: { ...formData, warehouseIds } })
      } else {
        const updateData = {
          email: formData.email,
          address: formData.address,
          firstName: formData.firstName,
          lastName: formData.lastName,
          active: !!formData.active,
          employeeCode: formData.employeeCode,
          mobileNo: formData.mobileNo,
        };
        await updateUser({ data: { ...updateData, },  userId: id })
        await assignWarehouse({ data: { warehouseIds, userId: formData.userId, groupName: formData.groupName, roleId: formData.roleId } })
        await fetchUsers();
      }
      setLoading(false);
      navigate('/settings/users');
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  }

  const onChangePassword = async (data, e) => {
    try {
      setLoading(true);
      await changePasswordAdmin({ data: { userId: user?.id, password: data.newPassword } })
      // await fetch();
      setLoading(false);
      showChangePasswordModal(false)
    } catch (error) {
      setLoading(false);
    }
  }

  const getRoleName = roleId => configData?.roles?.find(role => role.roleId === roleId)?.roleName

  const handleWarehouseSelect = (e, onChange) => {
    let selectedRoleName = getRoleName(selectedUserRoleId)
    if (selectedRoleName === ROLE_TYPES.FIELD_EXECUTIVE) {
      setSelectedWarehouses([e]);
      onChange([e]);
      return;
    }
    setSelectedWarehouses(selectedWarehouses.concat(e));
    onChange(selectedWarehouses.concat(e));
  }

  const handleWarehouseGroupSelect = (e, onChange) => {
    methods.setValue('assign-all-stores', '')
    onChange(e);
  }

  const handleAssignAllCheck = (isChecked, onChange) => {
    if (isChecked === 'true') {
      const warehousesFromGroupName = warehousesData?.filter(warehouse => warehouse.warehouseGroup === userGroup) || [];
      let warehouseValues = warehousesFromGroupName
        .map(warehouse => ({ label: warehouse.warehouseName, value: warehouse.id }))
        .filter(warehouse => !selectedWarehouses?.find(x => x.value === warehouse.value));
      methods.setValue('warehouses', selectedWarehouses.concat(warehouseValues));
      setSelectedWarehouses(selectedWarehouses.concat(warehouseValues));
    } else {
      const warehousesFromGroupName = warehousesData?.filter(warehouse => warehouse.warehouseGroup === userGroup) || [];
      let warehouseValues = selectedWarehouses.filter(warehouse => !warehousesFromGroupName?.find(x => x.id === warehouse.value));
      methods.setValue('warehouses', warehouseValues)
      setSelectedWarehouses(warehouseValues)
    }
    onChange(isChecked)
  }

  const getRoleOptions = () => {
    let allRoles = configData?.roles?.map(role => ({ label: role.roleName, value: role.roleId })) || []
    if (loggedInUserRole === ROLE_TYPES.ADMIN) {
      return allRoles
    } else if (loggedInUserRole === ROLE_TYPES.EG_ADMIN) {
      return allRoles.filter(role => role.label !== ROLE_TYPES.ADMIN)
    } else if (loggedInUserRole === ROLE_TYPES.EG_STORE) {
      return allRoles.filter(role => role.label !== ROLE_TYPES.ADMIN && role.label !== ROLE_TYPES.EG_ADMIN)
    } else {
      return [{ label: 'You do not have required permissions', value: '' }]
    }
  }

  useEffect(() => {
    if (user) {
      methods.reset({
        userId: user?.id,
        username: user?.username,
        email: user?.email,
        warehouses: user?.warehouses?.map(warehouse => ({ label: warehouse.name, value: warehouse.id })),
        groupName: user?.groupName,
        roleId: user?.roles?.[0]?.roleId
      })
      setSelectedWarehouses(user?.warehouses?.map(warehouse => ({ label: warehouse.name, value: warehouse.id })) ?? [])
    }
  }, [user])

  const SelectedWareHouses = () => {
    const wh = useWatch({ control: methods.control, name: 'warehouses' });

    return (
      <div className={cx(styles.warehouseList)}>
        {wh?.map((warehouse, index) => (
          <div className={cx(styles.warehouseListItem)}>
            <div key={index} >
              {warehouse.label}
            </div>
            <button type='button' className={cx(styles.delete)} onClick={() => {
              const values = methods.getValues('warehouses');
              values.splice(index, 1);
              methods.setValue('warehouses', values);
            }}>{CROSS_ICON}</button>
          </div>
        ))}
      </div>
    )
  }

  if (isUserLoading) return <div>Loading...</div>

  return (
    <div>
      {/* <h2>{i18n('User Detail')}</h2> */}
      <FormProvider {...methods} >
        <form className={cx(styles.form)} onSubmit={methods.handleSubmit(_handleSubmit)}>

          <div className={styles.fieldsWrapper}>
            <FormSectionTitle className={styles.formSectionTitle}>{i18n('User Information')}</FormSectionTitle>
            <div className={cx(styles.fields)}>
              <Controller
                control={methods.control}
                key={'username'}
                name={'username'}
                rules={{
                  required: i18n('Please enter username'),
                }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input
                    type="text"
                    label={i18n('User Name')}
                    error={error?.message}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    placeholder="user"
                    disabled={!isCreateUserFlow}
                  />
                )}
              />
              <Controller
                control={methods.control}
                key={'email'}
                name={'email'}
                rules={{
                  required: i18n('Email address is required'),
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: i18n('Please enter a valid email address'),
                  },
                  validate: {
                    // unique: async email => {
                    //   if (disabled) return true;
                    //   const { data } = await getCustomer(email);
                    //   return data?.email ? i18n('Email already exists') : true;
                    // },
                  }
                }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input
                    disabled={!isCreateUserFlow}
                    type="email"
                    label={i18n('Email Address')}
                    error={error?.message}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    placeholder='john@doe.com'
                  />
                )}
              />
              {isCreateUserFlow && (
                <Controller
                  control={methods.control}
                  key={'password'}
                  name={'password'}
                  rules={{
                    required: i18n('Please enter password'),
                  }}
                  render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                    <Input
                      type="text"
                      label={i18n('Password')}
                      error={error?.message}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      placeholder="xxxx"
                    />
                  )}
                />
              )}
              {loggedInUserRole === ROLE_TYPES.ADMIN && !isCreateUserFlow &&
                <div className={styles.updatePasswordButton}>
                  <Button type='button' theme='primary' onClick={() => {
                    setShowChangePasswordModal(true)
                  }}>{i18n('Change password')}</Button>
                </div>
              }
            </div>
            <div className={cx(styles.fields)}>
              <Controller
                control={methods.control}
                key={'firstName'}
                name={'firstName'}
                rules={{
                  // required: i18n('Please enter first name'),
                }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input
                    type="text"
                    label={i18n('First Name')}
                    error={error?.message}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    placeholder="John"
                  />
                )}
              />
              <Controller
                control={methods.control}
                key={'lastName'}
                name={'lastName'}
                rules={{

                }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input
                    disabled={false}
                    type="text"
                    label={i18n('Last Name')}
                    error={error?.message}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    placeholder='Doe'
                  />
                )}
              />
              <Controller
                control={methods.control}
                key={'employeeCode'}
                name={'employeeCode'}
                rules={{

                }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input
                    type="text"
                    label={i18n('Employee Code')}
                    error={error?.message}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    placeholder="emp123"
                  />
                )}
              />
              <Controller
                control={methods.control}
                key={'mobileNo'}
                name={'mobileNo'}
                rules={{}}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <Input type="number" label={i18n('Phone')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
                )}
              />
               <Controller
                control={methods.control}
                key={'active'}
                name={'active'}
                render={({ field: { value, onBlur, onChange }, fieldState: { error, name } }) => (
                  <Checkbox className={cx(styles['active-checkbox'])} name="active" value="active" checked={value} label={i18n('Active')} error={error?.message} onBlur={onBlur} onChange={onChange} />
                )}
              />
            </div>

            {(getRoleName(selectedUserRoleId) === ROLE_TYPES.FIELD_EXECUTIVE && fieldExecutiveForm) && (
              <>
                <div className={cx(styles.fields)}>
                  <UploadPrescription customLabel={'Upload User Image'} />
                </div>
                <div className={cx(styles.fields)}>
                  <Controller
                    control={methods.control}
                    key={'vehicleNo'}
                    name={'vehicleNo'}
                    rules={{

                    }}
                    render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                      <Input
                        type="text"
                        label={i18n('Vehicle Number')}
                        error={error?.message}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        placeholder=""
                      />
                    )}
                  />
                  <Controller
                    control={methods.control}
                    key={'address'}
                    name={'address'}
                    rules={{
                    }}
                    render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                      <Input
                        type="text"
                        label={i18n('Address')}
                        error={error?.message}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        placeholder=""
                      />
                    )}
                  />
                </div>
              </>
            )}
          </div>

          <div className={styles.fieldsWrapper}>
            <FormSectionTitle className={styles.formSectionTitle}>{i18n('Role and Warehouses')}</FormSectionTitle>
            <div className={cx(styles.fields)}>
              <Controller
                control={methods.control}
                name="roleId"
                rules={{ required: i18n('Please select a role') }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <SimpleSelect
                    label={i18n('Role')}
                    error={error?.message}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    options={!fieldExecutiveForm ? getRoleOptions() : [{ label: ROLE_TYPES.FIELD_EXECUTIVE, value: 5 }]} />
                )}
              />
              <Controller
                control={methods.control}
                name="groupName"
                rules={{ required: i18n('Please select a group'), validate: { minLength: value => value?.length > 0 || i18n('Please select a group') } }}
                render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                  <SimpleSelect
                    label={i18n('Group Name')}
                    error={error?.message}
                    onBlur={onBlur}
                    onChange={(e) => handleWarehouseGroupSelect(e, onChange)}
                    value={value}
                    options={configData?.setup?.find(x => x.setupKey === 'warehouseGroup')?.values?.map(grp => ({ label: grp.name, value: grp.name })) || []} />
                )}
              />

              <Controller
                control={methods.control}
                name={'assign-all-stores'}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Checkbox disabled={getRoleName(selectedUserRoleId) === ROLE_TYPES.FIELD_EXECUTIVE} className={styles['assign-all-checkbox']} value={'true'} checked={value === 'true'} label={i18n(`Assign all stores in "${userGroup}" group to user`)} name={'assign-all-stores'} error={error?.message} onChange={(e) => handleAssignAllCheck(e, onChange)} />
                )}
              />
              <div className={cx(styles.fields)}>
                <Controller
                  control={methods.control}
                  name="warehouses"
                  rules={{ required: i18n('At least one warehouse is required'), validate: { minLength: value => value?.length > 0 || i18n('At least one warehouse is required') } }}
                  render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                    <MultiSelect
                      label={i18n('Warehouses')}
                      error={error?.message}
                      onBlur={onBlur}
                      onChange={(e) => handleWarehouseSelect(e, onChange)}
                      value={[]}
                      options={getWarehouseOptions}
                      isSearchable
                      className={styles.warehouseMultiSelect}
                      placeholder={i18n('Search Warehouse')}
                      isMulti={getRoleName(selectedUserRoleId) !== ROLE_TYPES.FIELD_EXECUTIVE}
                    />
                  )}
                />
              </div>
            </div>
            <SelectedWareHouses />

          </div>
          <div className={cx(styles.fields)}>
            <Button type='button' theme='secondary' onClick={() => navigate('/settings/users')}>{i18n('Cancel')}</Button>
            <Button disabled={loading} type="submit">{isCreateUserFlow ? i18n('Create User') : i18n('Assign Warehouse')}</Button>
          </div>

        </form>
      </FormProvider>
      {showChangePasswordModal && <ChangePasswordModal
        onClose={() => setShowChangePasswordModal(false)}
        userId={user?.id}
        username={user?.username}
        onSubmit={(data) => {
          onChangePassword(data)
          setShowChangePasswordModal(false)
        }}
      />}
    </div >
  )
}

EditUser.propTypes = {}

export default EditUser
