import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import { request, ClientError } from 'graphql-request'
import { addCommentGql, addCustomerCommunicationCommentGql, bulkUploadCommunicationCommentsGql, bulkUploadSkuIdsGql, bulkUploadStaffNameGql, bulkUploadUsersGql, getCustomerByCountryGql, getCustomerGql, getCustomerInfoGql, getCustomersGql, getDefaultConfigsGql, getNewestCustomersGql, getNewestOrdersGql, getOrderGql, getOrdersGql, getPendingPrescriptionOrdersGql, getPredictedCustomersGql, getPredictedOrdersGql, getStaffGql, getStoresGql, getSummaryGql, getUsersGql, saveCustomerGql, updateDefaultConfigsGql, updateOrderGql } from './gql'
import { transformCreateCustomer, transformGetCustomer, transforGetmOrder, transformAddComment, transformAddedComment, transformAddedCommunication, transformUpdateOrder, transformBulkUploadCommunicationComments, transformGetStores, transformbulkUploadSkuIds, transformCustomers, transformGetSummary, transformUpdateDefaultConfigsRequest, transformGetDefaultConfigs, transformbulkUploadStaffName, transformGetStaff, transformPredictedCustomers, transformGetNewestCustomers, transformGetCustomerInfo, transformGetNewestOrders, transformPredictedOrders, transformGetOrders, transformBulkUploadUsers, transformGetUsers, transformStoresWithAvailability, transformNewestAppointments, transformFetchAppointments, transformAddPrescription, transformPerformanceMatrix, transformSearchedCustomers,egTargetData } from './request-transformers'
import { persistor } from './store';
dayjs.extend(utc);

const UTC_OFFSET = dayjs().utcOffset() / 60;

const graphqlBaseQuery = () => {
  return async ({ body, variables }, { getState }) => {
    const country = getState().app.defaults.country;
    const storeCodeQuery = country ? `?store=${country}-en` : '';

    try {
      const res = await request({
        url: `${process.env.REACT_APP_API_BASE_URL}/graphql${storeCodeQuery}`,
        document: body,
        variables,
        requestHeaders: {
          authorization: `Bearer ${getState().login.token}`,
        },
      })
      return { status: 200, data: res };
    } catch (error) {
      if (error.response.status === 401) {
        window.localStorage.removeItem('eg_token');
        window.location.href = '/login'
        persistor?.purge?.();
      };
      if (error instanceof ClientError) {
        return { error: { status: error.response.status, data: error.response.errors?.[0]?.message || error.response.message } }
      }
      return { error: { status: 500, data: error } }
    }
  }
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/`,
  prepareHeaders: (Headers, { getState }) => {
    const token = getState().login.token;
    if (token) {
      Headers.set('Authorization', `Bearer ${token}`);
    }
    Headers.set('content-type', 'application/json');
    return Headers;
  },
})

const restBaseQuery = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    window.localStorage.removeItem('eg_token');
    window.location.href = '/login'
    persistor?.purge?.();
  }
  return result
}

export const api = createApi({
  baseQuery: graphqlBaseQuery(),
  endpoints: build => ({
    createCustomer: build.mutation({
      query: (data) => ({
        method: 'POST',
        body: saveCustomerGql,
        variables: transformCreateCustomer(data),
      })
    }),
    addPrescription: build.mutation({
      query: (data) => ({
        method: 'POST',
        body: saveCustomerGql,
        variables: transformAddPrescription(data),
      })
    }),
    addComment: build.mutation({
      query: (data) => ({
        method: 'POST',
        body: addCommentGql,
        variables: transformAddComment(data),
      }),
      transformResponse: transformAddedComment
    }),
    addCustomerCommunicationComment: build.mutation({
      query: (data) => ({
        method: 'POST',
        body: addCustomerCommunicationCommentGql,
        variables: data,
      }),
      transformResponse: transformAddedCommunication
    }),
    getCustomer: build.query({
      query: (term) => ({
        body: getCustomerGql,
        variables: { term }
      }),
      transformResponse: transformGetCustomer
    }),
    getCustomerByCountry: build.query({
      query: ({ email, country }) => ({
        body: getCustomerByCountryGql,
        variables: { term: email, countryCode: country }
      }),
      transformResponse: transformGetCustomer
    }),
    updateOrder: build.mutation({
      query: (data) => ({
        body: updateOrderGql,
        variables: transformUpdateOrder(data),
      }),
    }),
    getOrder: build.query({
      query: (id) => ({
        body: getOrderGql,
        variables: { id },
      }),
      transformResponse: transforGetmOrder
    }),
    bulkUploadCommunicationComments: build.mutation({
      query: (data) => ({
        body: bulkUploadCommunicationCommentsGql,
        variables: transformBulkUploadCommunicationComments(data),
      }),
    }),
    bulkUploadSkuIds: build.mutation({
      query: (data) => ({
        body: bulkUploadSkuIdsGql,
        variables: transformbulkUploadSkuIds(data),
      }),
    }),
    bulkUploadUsers: build.mutation({
      query: (users) => ({
        body: bulkUploadUsersGql,
        variables: { input: { users: transformBulkUploadUsers(users) } },
      }),
    }),
    bulkUploadStaffName: build.mutation({
      query: (data) => ({
        body: bulkUploadStaffNameGql,
        variables: transformbulkUploadStaffName(data),
      }),
    }),
    getUsers: build.query({
      query: email => ({
        body: getUsersGql,
        variables: { email }
      }),
      transformResponse: transformGetUsers
    }),
    getStores: build.query({
      query: () => ({
        body: getStoresGql,
      }),
      transformResponse: transformGetStores
    }),
    getStaff: build.query({
      query: () => ({
        body: getStaffGql,
      }),
      transformResponse: transformGetStaff
    }),
    getCustomers: build.query({
      query: (variables) => ({
        body: getCustomersGql,
        variables
      }),
      transformResponse: transformCustomers
    }),
    getPredictedCustomers: build.query({
      query: (term) => ({
        body: getPredictedCustomersGql,
        variables: { filter: { term }, perPage: 6 }
      }),
      transformResponse: transformPredictedCustomers
    }),
    searchCustomer: build.query({
      query: (term) => ({
        body: getPredictedCustomersGql,
        variables: { filter: { term }, perPage: 1 }
      }),
      transformResponse: transformSearchedCustomers
    }),
    getNewestCustomers: build.query({
      query: () => ({
        body: getNewestCustomersGql,
        variables: { filter: { term: '' }, perPage: 2 }
      }),
      transformResponse: transformGetNewestCustomers
    }),
    getSummary: build.query({
      query: (variables) => ({
        body: getSummaryGql,
        variables
      }),
      transformResponse: transformGetSummary
    }),
    getDefaultConfigs: build.query({
      query: () => ({
        body: getDefaultConfigsGql,
      }),
      transformResponse: transformGetDefaultConfigs
    }),
    updateDefaultConfigs: build.mutation({
      query: (data) => ({
        body: updateDefaultConfigsGql,
        variables: transformUpdateDefaultConfigsRequest(data),
      }),
    }),

    getCustomerInfo: build.query({
      query: (term) => ({
        body: getCustomerInfoGql,
        variables: { term }
      }),
      transformResponse: transformGetCustomerInfo
    }),

    getPredictedOrders: build.query({
      query: (term) => ({
        body: getPredictedOrdersGql,
        variables: { filter: { term }, perPage: 6 }
      }),
      transformResponse: transformPredictedOrders
    }),

    getNewestOrders: build.query({
      query: () => ({
        body: getNewestOrdersGql,
        variables: { filter: { term: '' }, perPage: 2 }
      }),
      transformResponse: transformGetNewestOrders
    }),

    getOrders: build.query({
      query: ({ page, ...filter }) => ({
        body: getOrdersGql,
        variables: { filter, perPage: 9, page }
      }),
      transformResponse: transformGetOrders
    }),

    getPendingPrescriptionOrders: build.query({
      query: ({ page, ...filter }) => ({
        body: getPendingPrescriptionOrdersGql,
        variables: { filter, perPage: 25, page }
      }),
      transformResponse: transformGetOrders
    }),
  })
})

export const rest = createApi({
  baseQuery: restBaseQuery,
  reducerPath: 'rest',
  endpoints: build => ({
    fetchWorkingHours: build.query({
      query: ({ storeId, weekIds, country }) => ({
        url: `staff/appointment/${country}-en/working/hour`,
        params: { storeId, weekIds }
      }),
    }),

    fetchWorkingHoursAll: build.query({
      query: ({ weekIds, country }) => ({
        url: `staff/appointment/${country}-en/working/hour/all`,
        params: { weekIds }
      }),
    }),

    setWorkingHours: build.mutation({
      query: ({ storeId, hours, weekId, country }) => ({
        url: `staff/appointment/${country}-en/working/hour`,
        method: 'PUT',
        body: { storeId, workingHours: hours, weekId, offset: UTC_OFFSET }
      }),
    }),

    getCalendar: build.query({
      query: ({ storeId, date, country }) => ({
        url: `staff/appointment/${country}-en/availability`,
        params: { storeId, date, offset: UTC_OFFSET }
      }),
    }),

    updateStoreActivation: build.mutation({
      query: ({ storeId, active, country }) => ({
        url: `staff/appointment/${country}-en/working/hour/activation`,
        method: 'PUT',
        body: { storeId, active }
      })
    }),

    bookAppointmentSlot: build.mutation({
      query: ({ slotId, customerId, country }) => ({
        url: `staff/appointment/${country}-en/book`,
        method: 'POST',
        body: { slotId, customerId }
      })
    }),

    fetchAppointments: build.mutation({
      query: ({ storeId, fromTime, toTime, size, page, country, appointmentId }) => ({
        url: `staff/appointment/${country}-en`,
        method: 'POST',
        body: { offset: UTC_OFFSET, storeId, fromTime, toTime, size, page, appointmentId: appointmentId ? appointmentId : undefined }
      }),
      transformResponse: (response, meta, arg) => transformFetchAppointments(response, meta, arg)
    }),

    fetchAppointmentsCustomerSupport: build.mutation({
      query: (body) => ({
        url: `staff/appointment/${body.country}-en`,
        method: 'POST',
        body: { offset: UTC_OFFSET, storeId: body.storeId, fromTime: body.fromTime, toTime: body.toTime, size: body.size, page: body.page, term: body.term, status: body.status }
      }),
    }),

    fetchNewestAppointments: build.mutation({
      query: ({ storeId, country }) => ({
        url: `staff/appointment/${country}-en?home=true`,
        method: 'POST',
        body: {
          offset: UTC_OFFSET,
          storeId,
          fromTime: dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          toTime: dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          size: 100,
          page: 0
        }
      }),
      transformResponse: transformNewestAppointments
    }),

    fetchStoresWithAvailability: build.query({
      async queryFn({ storeIds, country }, _queryApi, _extraOptions, fetchWithBQ) {
        const res = await Promise.all(storeIds.map(storeId => fetchWithBQ({ url: `staff/appointment/${country}-en/working/hour`, params: { storeId } })))
        return { data: transformStoresWithAvailability(res.map(x => x.data)) }
      }
    }),

    markAppointShow: build.query({
      query: ({ appointmentId, show, country }) => ({
        url: `staff/appointment/${country}-en/show/${appointmentId}`,
        method: 'PUT',
        params: { show }
      }),
    }),

    cancelAppointment: build.query({
      query: ({ appointmentId, country, reason }) => ({
        url: `staff/appointment/${country}-en/cancel`,
        method: 'PUT',
        body: { appointmentId, reason }
      }),
    }),

    confirmAppointment: build.query({
      query: ({ appointmentId, country }) => ({
        url: `staff/appointment/${country}-en/confirm/${appointmentId}`,
        method: 'PUT',
        body: { appointmentId }
      }),
    }),

    warehouses: build.query({
      query: () => ({
        url: `${process.env.REACT_APP_MIDDLEWARE_BASE_URL}/api/v1/misc/warehouse-details`,
      }),
    }),

    externalDoctors: build.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/misc/doctors`,
      }),
    }),

    updateWarehouse: build.mutation({
      query: ({ data: body }) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/update-warehouse`,
        method: 'PUT',
        body
      }),
    }),

    addWarehouse: build.mutation({
      query: ({ data: body }) => ({
        url: `${process.env.REACT_APP_MIDDLEWARE_BASE_URL}/api/v1/misc/add-warehouse`,
        method: 'POST',
        body
      }),
    }),

    assignWarehouse: build.mutation({
      query: ({ data: body }) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/user/assign-warehouse`,
        method: 'POST',
        body
      }),
    }),

    fetchOrderDetails: build.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_MIDDLEWARE_BASE_URL}/api/v1/eg/order/spae/${id}`,
      }),
    }),

    warehouseSetup: build.query({
      query: () => ({
        url: `${process.env.REACT_APP_MIDDLEWARE_BASE_URL}/api/v1/misc/warehouse/setup`,
      }),
    }),

    updateWarehouseSetup: build.mutation({
      query: ({ data: body }) => ({
        url: `${process.env.REACT_APP_MIDDLEWARE_BASE_URL}/api/v1/misc/warehouse/setup`,
        method: 'PUT',
        body,
      }),
    }),

    users: build.query({
      query: (roleIds) => ({
        url: roleIds ? `${process.env.REACT_APP_API_BASE_URL}/get-users?roleIds=${roleIds}` : `${process.env.REACT_APP_API_BASE_URL}/get-users`,
      }),
    }),

    createUser: build.mutation({
      query: ({ data: body }) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/add-users`,
        method: 'POST',
        body,
      }),
    }),

    updateUser: build.mutation({
      query: ({ data, userId }) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/${userId}/update`,
        method: 'POST',
        body: data,
      }),
    }),

    login: build.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/login`,
        method: 'POST',
        body,
      }),
    }),
    switchStore: build.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/switch-store/${id}`,
      }),
    }),

    changePasswordAdmin: build.mutation({
      query: ({ data: body }) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/user/admin/change-password`,
        method: 'POST',
        body,
      }),
    }),

    changePasswordUser: build.mutation({
      query: ({ data: body }) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/user/change-password`,
        method: 'POST',
        body,
      }),
    }),

    getOrdersViaRest: build.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_MIDDLEWARE_BASE_URL}/api/v1/eg/order/elastic`,
        method: 'POST',
        body,
      })
    }),
    fetchInvoice: build.mutation({
      query: ({ id, customer_name, invoice_number }) => ({
        url: `${process.env.REACT_APP_MIDDLEWARE_BASE_URL}/api/v1/misc/invoice/${id}`,
        method: 'POST',
        body: { invoice_number, customer_name },
      })
    }),

    getPerformanceMetrics: build.query({
      query: ({ country, startDate, endDate }) => ({
        url: `/customer-appointment/${country}-en/performance-matrix`,
        method: 'POST',
        body: { startDate, endDate }
      }),
      transformResponse: transformPerformanceMatrix
    }),

    getPerformanceRanking: build.query({
      query: ({ country }) => ({
        url: `/customer-appointment/${country}-en/performance-ranking`,
        method: 'GET',
      }),
      // transformResponse: transformPerformanceMatrix
    }),

    setCustomerHistoryInformation: build.mutation({
      query: (payload) => ({
        url: `/customer-information`,
        method: 'POST',
        body: payload
      }),
    }),

    getCustomerHistoryInformation: build.query({
      query: (customerId) => ({
        url: `/customer-information?customerId=${customerId}`,
        method: 'GET',
      }),
    }),

    getCustomersAppointments: build.query({
      query: ({customerId, storeCode}) => ({
        url: `/staff/appointment/${storeCode}-en/customer/${customerId}`,
        method: 'GET'
      })
    }),
    getTransferOrdersViaRest: build.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_MIDDLEWARE_BASE_URL}/api/v1/transfer-order/sync/search`,
        method: 'POST',
        body,
      })
    }),

    sendOTP: build.mutation({
      query: ({ countryCode, data: body }) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/${countryCode}-en/otp/send`,
        method: 'POST',
        body,
      }),
      transformResponse: (response) => response
    }),

    verifyOTP: build.mutation({
      query: ({ countryCode, data: body }) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/${countryCode}-en/otp/verify`,
        method: 'POST',
        body,
      }),
      transformResponse: (response) => response
    }),

    getDeliverySla: build.mutation({
      query: ({ data: body }) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/misc/sla`,
        method: 'POST',
        body,
      }),
      transformResponse: (response) => response
    }),

    fetchPrescriptionById: build.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer-prescription/prescription/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response
    }),

    updateTransferOrderContactedStatus: build.query({
      query: ({ id, callStatus }) => ({
        url: `${process.env.REACT_APP_MIDDLEWARE_BASE_URL}/api/v1/transfer-order/sync/ttb-customer-contacted/${id}/${callStatus}`,
        method: 'GET',
      }),
    }),

    addComprehensivePrescription: build.mutation({
      query: ({ data: body }) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer-prescription/comprehensive`,
        method: 'POST',
        body,
      }),
    }),

    getComprehensivePrescriptionById: build.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer-prescription/comprehensive/${id}`,
        method: 'GET',
      }),
    }),

    getComprehensivePrescriptionByCustomerId: build.query({
      query: (customerId) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer-prescription/comprehensive?customerId=${customerId}`,
        method: 'GET',
      }),
    }),

    getEgTarget: build.query({
      query: () => ({
        url: `/eg-target`,
        method: 'GET',
      }),
      transformResponse: egTargetData
    }),

    addCustomerEgSkuMapping: build.mutation({
      query: ({ data: body, country, customerId }) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer-prescription/customer-eg-sku-mapping/${country}-en/${customerId}`,
        method: 'POST',
        body,
      }),
    }),

    getCustomerEgSkuMapping: build.query({
      query: ({ country, customerId }) => ({
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer-prescription/customer-eg-sku-mapping/${customerId}`,
        method: 'GET',
      }),
    }),

  }),

})

export const {
  useCreateCustomerMutation,
  useAddCommentMutation,
  useAddCustomerCommunicationCommentMutation,
  useLazyGetCustomerQuery,
  useLazyGetCustomerByCountryQuery,
  useLazyGetOrderQuery,
  useUpdateOrderMutation,
  useBulkUploadCommunicationCommentsMutation,
  useBulkUploadSkuIdsMutation,
  useBulkUploadStaffNameMutation,
  useBulkUploadUsersMutation,
  useLazyGetUsersQuery,
  useLazyGetStoresQuery,
  useLazyGetStaffQuery,
  useLazyGetCustomersQuery,
  useLazyGetSummaryQuery,
  useLazyGetDefaultConfigsQuery,
  useUpdateDefaultConfigsMutation,
  useAddPrescriptionMutation,

  useLazyGetPredictedCustomersQuery,
  useLazyGetNewestCustomersQuery,
  useLazyGetCustomerInfoQuery,
  useLazyGetNewestOrdersQuery,
  useLazyGetPredictedOrdersQuery,
  useLazyGetOrdersQuery,
  useLazyGetPendingPrescriptionOrdersQuery,
  useLazySearchCustomerQuery,

} = api;

export const {
  useLazyFetchWorkingHoursQuery,
  useSetWorkingHoursMutation,
  useLazyFetchStoresWithAvailabilityQuery,
  useUpdateStoreActivationMutation,
  useLazyGetCalendarQuery,
  useBookAppointmentSlotMutation,
  useFetchAppointmentsMutation,
  useFetchNewestAppointmentsMutation,
  useLazyMarkAppointShowQuery,
  useLazyCancelAppointmentQuery,
  useLazyConfirmAppointmentQuery,
  useLazyWarehousesQuery,
  useUpdateWarehouseMutation,
  useAddWarehouseMutation,
  useLazyExternalDoctorsQuery,
  useLazyFetchOrderDetailsQuery,
  useWarehouseSetupQuery,
  useUpdateWarehouseSetupMutation,
  useUsersQuery,
  useLazyUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useAssignWarehouseMutation,
  useWarehousesQuery,
  useLoginMutation,
  useLazySwitchStoreQuery,
  useChangePasswordAdminMutation,
  useChangePasswordUserMutation,
  useGetOrdersViaRestMutation,
  useFetchInvoiceMutation,
  useGetPerformanceMetricsQuery,
  useLazyGetPerformanceMetricsQuery,
  useLazyGetPerformanceRankingQuery,
  useSetCustomerHistoryInformationMutation,
  useGetCustomerHistoryInformationQuery,
  useLazyGetCustomerHistoryInformationQuery,
  useLazyGetCustomersAppointmentsQuery,
  useGetTransferOrdersViaRestMutation,
  useLazyFetchWorkingHoursAllQuery,
  useSendOTPMutation,
  useVerifyOTPMutation,
  useGetDeliverySlaMutation,
  useLazyFetchPrescriptionByIdQuery,
  useLazyUpdateTransferOrderContactedStatusQuery,
  useAddComprehensivePrescriptionMutation,
  useLazyGetComprehensivePrescriptionByIdQuery,
  useLazyGetComprehensivePrescriptionByCustomerIdQuery,
  useFetchAppointmentsCustomerSupportMutation,
  useGetEgTargetQuery,
  useLazyGetEgTargetQuery,
  useAddCustomerEgSkuMappingMutation,
  useLazyGetCustomerEgSkuMappingQuery
} = rest;

