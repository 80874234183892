import { LENS_TYPES } from 'common-hooks/useFields';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { COUNTRIES } from 'hooks/useValidation';
dayjs.extend(utc);

const parseFullName = (firstName, lastName) => [firstName, lastName].filter(Boolean).join(' ');

export const convertBodyToQuery = (body) => {
  const query = Object.keys(body).map(key => {
    if (body[key] === undefined || body[key] === null || body[key] === '') return '';
    if (Array.isArray(body[key])) {
      return body[key].map(val => `${key}=${val}`).join('&');
    } else {
      return `${key}=${body[key]}`;
    }
  }).filter(x => !!x).join('&');

  return query;
}

export const transformCreateCustomer = data => {
  const transformedData = { ...data };

  transformedData.mobileNumber = transformedData.phone;
  transformedData.countryCodeMobile = transformedData.code;

  if (transformedData.fullName) {
    transformedData.fullName = transformedData.fullName.split(' ');
    transformedData.firstName = transformedData.fullName.shift();
    transformedData.lastName = transformedData.fullName.join(' ');
    delete transformedData.fullName;
  }

  if (!transformedData.prescriptions) transformedData.prescriptions = [];
  transformedData.prescriptions = transformedData.prescriptions.filter(x => !x.id);
  transformedData.prescriptions.forEach((item) => {
    if (item.comment && !item.comment?.comment) {
      delete item.comment;
    }
    if (item.prescriptionImageUpload) {
      delete item.prescriptionImageUpload;
    }

    if (item.lensDiameter) delete item.lensDiameter;
    if (item.index) delete item.index;
  })

  if (transformedData.code) {
    delete transformedData.code;
  }

  if (transformedData.phone) {
    delete transformedData.phone;
  }

  if (transformedData.dateOfBirth) {
    transformedData.dateOfBirth = new Date(transformedData.dateOfBirth).toISOString();
  }

  return transformedData;
}

export const transformAddPrescription = data => {
  const transformedData = { ...data };

  if (!transformedData.prescriptions) transformedData.prescriptions = [];
  transformedData.prescriptions = transformedData.prescriptions.filter(x => !x.id);
  transformedData.prescriptions.forEach((item) => {
    if (item.lensType === 'Plano') {
      item.lensType = LENS_TYPES.SINGLE_VISION;
    }

    if (item.comment && !item.comment?.comment) {
      delete item.comment;
    }
    if (item.prescriptionImageUpload) {
      delete item.prescriptionImageUpload;
    }

    item.externalPrescription = !!item.externalPrescription;

    if (item.lensDiameter) delete item.lensDiameter;
    if (item.index) delete item.index;
  })

  return transformedData;
}

export const transformGetCustomer = data => { // OLD only
  const transformedData = { ...(data.getCustomerByCountry || data.getCustomer) };
  const phone = transformedData.phone.replace('-', '').replace('+', '');
  const currentCountry = COUNTRIES.find(x => phone.startsWith(x.isd)) || {};

  const parsedIsd = currentCountry.isd ? `+${currentCountry.isd}` : '';
  const parsedPhone = phone.split(currentCountry.isd)[1] || phone;
  transformedData.country = currentCountry.code;
  transformedData.phone = [parsedIsd, parsedPhone].filter(Boolean).join('-');
  if (transformedData.gender) transformedData.gender = transformedData.gender.toLowerCase();

  return transformedData;
}

export const transforGetmOrder = data => {
  const transformedData = { ...data.getOrder };
  transformedData.products.forEach(x => {
    const nosebridge = x.noseBridge?.split?.(' mm')?.[0];
    const lensWidth = x.lensWidth?.split?.(' mm')?.[0];
    x.sku = x.sku.toUpperCase();
    x.lensWidth = lensWidth ? parseInt(lensWidth) : 0;
    x.noseBridge = nosebridge ? parseInt(nosebridge) : 0;
  })
  return transformedData;
}

export const transformAddComment = data => {
  const transformedData = { ...data };
  transformedData.comment = transformedData.addComment;
  delete transformedData.addComment;
  return transformedData;
}

export const transformAddedComment = data => {
  return data?.addComment;
}

export const transformAddedCommunication = data => {
  return data?.addCustomerCommunicationComment;
}

export const transformUpdateOrder = data => {
  const transformedData = {};
  transformedData.products = data.products.map(prod => ({
    sku: prod.sku,
    parentItemId: prod.parentItemId || undefined,
    childItemId: prod.childItemId || undefined,
    prescriptionDoctorName: prod.power.prescriptionDoctorName,
    externalPrescription: !!prod.power.externalPrescription,
    power: {
      lensType: prod.power.lensType,
      lensShape: prod.power.lensShape,
      left: prod.power.left,
      right: prod.power.right,
      addOn1: prod.power.addOn1,
      addOn2: prod.power.addOn2,
      addOn3: prod.power.addOn3,
      addOn4: prod.power.addOn4,
      addOn5: prod.power.addOn5,
      addOn6: prod.power.addOn6,
      addOn7: prod.power.addOn7,
      addOn8: prod.power.addOn8,
      addOnColor: prod.power.addOnColor,
      addOnPackage: prod.power.addOnPackage,
      comment: prod.power.comment?.comment,
      index: prod.power.index,
      prescriptionImageUpload: prod.power.prescriptionImageUpload,
      baseCurve: prod.power.baseCurve,
      lensDiameter: prod.power.lensDiameter,
      corridor: prod.power.corridor
    },
    id: prod.id
  }));
  transformedData.id = data.id
  transformedData.orderNumber = data.orderNumber

  return transformedData;
}

export const transformBulkUploadCommunicationComments = data => {
  const transformedData = {};

  let columns = data.columns.map(x => x.trim().toLowerCase().replace(/\W/g, ''));
  columns = columns.map(col => {
    if (col === 'orderid') {
      return 'orderId';
    } else if (col === 'channel' || col === 'channels') {
      return 'channel';
    } else if (col === 'staffname') {
      return 'staffName';
    } else if (col.includes('comment')) {
      return 'comment';
    } else if (col.includes('timestamp')) {
      return 'timestamp';
    } else {
      return col;
    }
  })

  const rows = data.rows.map(row => {
    const obj = {};
    columns.forEach((col, i) => {
      let val = row.data[i];
      val = val?.trim?.().toLowerCase?.().replace?.(/\W/g, '');

      if (val === 'whatsapp') {
        val = 'Whatsapp'
      } else if (val === 'email') {
        val = 'Email'
      } else if (val === 'call') {
        val = 'Call'
      } else if (val === 'other') {
        val = 'Other'
      } else {
        val = row.data[i];
      };

      obj[col] = val;
    });

    return obj;
  });

  transformedData.communicationComments = rows;

  return transformedData;
}

export const transformbulkUploadSkuIds = skuIds => {
  return { skuIds };
}

export const transformBulkUploadUsers = data => {
  const columns = data.columns.map(x => x.trim().toLowerCase().replace(/\W/g, ''));

  const rows = data.rows.map(row => {
    const obj = {};
    columns.forEach((col, i) => {
      let val = row[i];
      val = val.trim();
      if (col === 'roles' || col === 'stores') {
        val = val.split(',').map(x => x.trim().toUpperCase())
      }
      obj[col] = val;
    });

    return obj;
  });

  return rows;
}

export const transformGetStores = data => {
  return data?.getStores?.map?.(store => ({
    value: store.code,
    label: [store.centreName, store.city, store.country].join(', '),
    city: store.city,
    country: store.country,
  })) || [];
}

export const transformGetUsers = data => {
  return data?.getUsers || [];
}

export const transformCustomers = data => {
  const customers = data?.getCustomers?.data || [];
  const transformedData = customers.map(customer => {
    const date = new Date(customer.createdAt);

    return {
      date: `${date.toLocaleString()}`,
      firstName: customer.firstName,
      lastName: customer.lastName,
      phone: customer.phone,
      email: customer.email,
      staffEmail: customer.staffEmail,
      hasPrescriptions: !!customer.prescriptions?.length,
    }
  })

  return transformedData;
}

export const transformGetSummary = data => {
  return data?.getSummary || {}
}

export const transformGetDefaultConfigs = data => {
  return {
    storeName: data?.getDefaultConfigs?.store,
    country: data?.getDefaultConfigs?.country,
    staffName: data?.getDefaultConfigs?.staffName
  };
}

export const transformUpdateDefaultConfigsRequest = (data = {}) => {
  return {
    UpdateConfigRequest: {
      store: data.storeName,
      country: data.country,
      staffName: data.staffName
    }
  }
}

export const transformbulkUploadStaffName = staffDetails => {
  return { input: { staffDetails: staffDetails.map(name => ({ name })) } };
}

export const transformGetStaff = data => {
  return data?.getStaffDetails?.map?.(({ name }) => ({ label: name, value: name })) || [];
}

export const transformPredictedCustomers = data => {
  const customers = data?.getCustomers?.data || [];
  const transformedData = customers.map(customer => {
    return {
      label: parseFullName(customer.firstName, customer.lastName),
      value: customer.id,
    }
  })

  return transformedData;
}

export const transformSearchedCustomers = data => {
  const customers = data?.getCustomers?.data || [];
  const transformedData = customers.map(customer => {
    return {
      fullName: parseFullName(customer.firstName, customer.lastName),
      ...customer
    }
  })

  return transformedData;
}

export const transformGetNewestCustomers = data => {
  const customers = data?.getCustomers?.data || [];
  const transformedData = customers.map(customer => {
    return {
      fullName: parseFullName(customer.firstName, customer.lastName),
      email: customer.email,
      phone: [customer.countryCodeMobile, customer.mobileNumber].filter(Boolean).join(''),
      id: customer.id,
      prescriptionCount: customer.prescriptions.length
    }
  })

  return transformedData;
}

export const transformPredictedOrders = data => {
  const orders = data?.getOrders || data || [];
  const transformedData = orders.map(order => {
    return {
      label: order.id,
      value: order.id
    }
  })

  return transformedData;
}

export const transformGetOrders = data => {
  const orders = data?.getOrders || data?.getPendingPrescriptionOrders || [];
  const transformedData = orders.map(order => {
    return {
      id: order.id,
      customer: order.clientName,
      date: dayjs(order.orderCreatedAt.split('Z')?.[0] || order.orderCreatedAt).format('D MMMM YYYY'),
      status: order.orderStatus,
      store: order.fulfilmentCentre?.name
    }
  })

  return transformedData;
}

export const transformGetNewestOrders = data => {
  const orders = data?.getOrders || [];
  const transformedData = orders.map(order => {
    return {
      id: order.id,
      status: order.orderStatus,
      date: dayjs(order.orderCreatedAt).format('D MMMM YYYY'),
      name: order.clientName
    }
  })

  return transformedData;
}

export const transformGetCustomerInfo = data => {
  const transformedData = { ...data.getCustomer };
  const code = transformedData?.phone?.replace?.('+', '')?.substring?.(0, 3);
  transformedData.fullName = parseFullName(transformedData.firstName, transformedData.lastName);
  transformedData.phone = transformedData.phone.split(code)[1];
  transformedData.code = code;
  const dob = new Date(transformedData.dateOfBirth);
  const age = new Date().getFullYear() - dob.getFullYear();
  transformedData.dateOfBirth = dayjs(transformedData.dateOfBirth).format('YYYY-MM-DD');
  transformedData.age = age;
  if (transformedData.gender) transformedData.gender = transformedData.gender.toLowerCase();

  return transformedData;
}

export const transformStoresWithAvailability = data => {
  return data
    .filter(x => x.defaultWorkingHours?.workingHours?.length || x.customWorkingHours)
    .map(x => ({ storeId: x.storeId, active: x.defaultWorkingHours.active }));
}

export const transformNewestAppointments = data => {
  const dayjsToday = dayjs();

  return data.filter(x => x.status !== 'MISSED').map(x => {
    const dayjsDate = dayjs(x.fromDate).local();
    const isToday = dayjs(x.fromDate).local().isSame(dayjsToday, 'day');
    return {
      id: x.appointmentId,
      age: x.customer.dob ? dayjs(dayjsToday).diff(x.customer.dob, 'year') : undefined,
      email: x.customer.email,
      name: [x.customer.firstName, x.customer.lastName].filter(Boolean).join(' '),
      phone: x.customer.phone,
      date: isToday ? `Today, ${dayjsDate.format('HH:mm')}` : dayjsDate.format('dddd DD, HH:mm'),
      customerId: x.customer.id
    }
  })
}

export const transformFetchAppointments = (data, meta, arg) => {
  const obj = {};
  data.forEach(x => {
    const fromDateOnly = x.fromDate.split('T')[0];
    if (!obj[fromDateOnly]) obj[fromDateOnly] = [];
    obj[fromDateOnly].push(x)
  })

  const ordered = arg['toTime'] ? Object.keys(obj).sort((a, b) => b - a).reduce((orderedObject, key) => {
    orderedObject[key] = obj[key];
    return orderedObject;
  }, {}) :
    Object.keys(obj).sort().reduce((orderedObject, key) => {
      orderedObject[key] = obj[key];
      return orderedObject;
    }, {});

  return ordered;
}


export const transformPerformanceMatrix = data => {
  return data;
}

export const egTargetData = data => {
  return data;
}
