import React, { useEffect, useMemo, useState } from 'react'
import HomeCard from './HomeCard'
import { BoxLink, Button } from 'components'
import orderIcon from './icons/order.svg'
import add from './icons/add.svg'
import useI18n from 'common-hooks/useI18n'
import cx from 'classnames'
import styles from './Home.module.scss'
import TabularSummary from 'components/tabular-summary/TabularSummary'
import { useSelector } from 'react-redux'
import { selectToken } from 'features/login/loginSlice'
import defaultAvatar from './icons/dashboard.svg'
import { selectDefaults } from 'appSlice'
import { useGetPerformanceMatrixQuery, useLazyGetPerformanceMatrixQuery, useLazyGetPerformanceRankingQuery,useLazyGetEgTargetQuery } from 'app/service'
import dayjs from 'dayjs'

function TopPerformersCard() {
  const { i18n } = useI18n();
  const token = useSelector(selectToken)
  const { storeName, country } = useSelector(selectDefaults);
  const [focused, setFocused] = useState(false);
  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);
  const [isTotalView, setIsTotalView] = useState(false);
  //const [getPerformanceRanking, { isLoading, isError, error, data: topPerformers = [] }] = useLazyGetPerformanceRankingQuery()
  const [getEgTarget, { data: egTargetData = {store: []}}] = useLazyGetEgTargetQuery();
  useEffect(() => {
    if(token) {
      getEgTarget()
    }
  }, [token])

  const month = dayjs().add(2,'days').format('MMMM');

  return (
    <HomeCard focused={focused}
      icon={defaultAvatar} title={i18n( `${month} Monthly Target` )}
      description={i18n('')}>
      <div>
      <div className={styles.tabularSummaryWrapper}>
          
            <TabularSummary
              title=" "
              items={[
                {term: i18n('Net Target'), def: egTargetData.netTarget},
                {term: i18n('Daily ET Target'), def: egTargetData.etTarget},
                {term: i18n('Point Target'), def: egTargetData.lensPointTarget}
              ]}
              isMetricsView
              
            />
            
          
        </div>
      </div>
    </HomeCard>
  )
}

TopPerformersCard.propTypes = {}

export default TopPerformersCard
