import React from 'react'
import cx from 'classnames'
import styles from './order/Order.module.scss'
import useI18n from 'common-hooks/useI18n'
import PropTypes from 'prop-types'

export function Resync(retryCount) {
  const { i18n } = useI18n();

  return (
    <div className={cx(styles.sync)}>
      {retryCount < 3 ? (<>
        <p className={cx(styles['sync-message'])}>{i18n('Syncing order, please wait')}</p>
        <div className={cx(styles['sync-loader'])} />
      </>) :
        <p className={cx(styles['sync-message'])}>{i18n('Failed to sync order')}</p>
      }
    </div>
  )
}

Resync.propTypes = {
  retryCount: PropTypes.number,
}
