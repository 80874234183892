import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useAddCustomerEgSkuMappingMutation, useAddPrescriptionMutation } from 'app/service'
import { ADDITIONAL_POWER, DEFAULT_VALUES } from 'components/prescription/prescription-constants'
import { useLocation, useNavigate } from 'react-router'
import { Button, IndexSelector, Input, Message, SimpleSelect } from 'components'
import prescriptionIcon from './prescription.png'
import useI18n from 'common-hooks/useI18n'
import { selectUser } from 'features/login/loginSlice'
import { useSelector } from 'react-redux'
import { selectDefaults } from 'appSlice'
import { toast } from 'react-toastify'
import cx from 'classnames'
import styles from './List.module.scss'
import { CORRIDOR } from 'common-hooks/useFields'

function AddExtraData({ customer }) {
  const { i18n } = useI18n();
  const location = useLocation();
  const methods = useForm({ mode: 'all', defaultValues: { left: {
    segmentHeight: DEFAULT_VALUES.segmentHeight,
    additionalPower: DEFAULT_VALUES.additionalPower
  }, right: {
    segmentHeight: DEFAULT_VALUES.segmentHeight,
    additionalPower: DEFAULT_VALUES.additionalPower
  } } });
  const [isImagesUploading, setIsImagesUploading] = useState(false);
  // const [addPrescription, { data, isLoading: isSubmitting }] = useAddPrescriptionMutation();
  const { name: staffName } = useSelector(selectUser);
  const { country } = useSelector(selectDefaults);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [add] = useAddCustomerEgSkuMappingMutation();

  const handleSubmit = async formData => {
    console.log('formData', formData)
    try {
      // const { data: response } = await addPrescription({ ...customer, countryCode: country, prescriptions: [{ ...formData, prescriptionUrl }] });
      // if (response) {
      //   navigate(`/delivery-promise?prescriptionId=${response?.saveCustomerPrescriptions?.latestPrescriptionId}`);
      // }
      await add({ data: { ...formData }, country, customerId: customer.id });
      navigate(location.pathname.replace('extraData/add', ''));
    } catch (error) {
      toast.error(i18n('Failed to add prescription'));
    } finally {
      setIsImagesUploading(false);
    }
  }

  useEffect(() => {
    if (staffName) {
      methods.setValue('staffName', staffName);
    }
  }, [staffName])

  useEffect(() => {
    if (state?.appointmentId) {
      methods.setValue('appointmentId', state.appointmentId);
    }
  }, [state?.appointmentId])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)} className={cx(styles.form)}>
        <h2 className={cx(styles.label)}>{'Extra Data form'}</h2>
        <Controller
          key={'sku'}
          name={'sku'}
          rules={{ required: i18n('Sku is required') }}
          render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
            <Input className={cx(styles.corridorField, styles.field)} label={i18n('SKU')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
          )}
        />
        <div className={cx(styles.cols)}>
          <div className={cx(styles.col)}>
            <h3 className={cx(styles.heading)}>{i18n('Right eye (OD)')}</h3>
            <Controller
              name={`right.segmentHeight`}
              defaultValue={DEFAULT_VALUES.segmentHeight}
              rules={{}}
              render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                <Input className={styles.field} placeholder={i18n('Segment Height (SH)')} label={i18n('Segment Height (SH)')} onBlur={onBlur} onChange={onChange} type="number" value={value} error={error?.message} />
              )}
            />
            <Controller
              name={`right.additionalPower`}
              defaultValue={DEFAULT_VALUES.additionalPower}
              render={({ field: { value, onChange } }) => (
                <IndexSelector className={styles.field} value={value} onChange={onChange} label={i18n('Additional Power (ADD)')} options={ADDITIONAL_POWER} title={i18n('Select Axis (AXIS)')} />
              )}
            />
          </div>
          <div className={cx(styles.col)}>
            <h3 className={cx(styles.heading)}>{i18n('Left eye (OS)')}</h3>
            <Controller
              name={`left.segmentHeight`}
              defaultValue={DEFAULT_VALUES.segmentHeight}
              rules={{}}
              render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                <Input className={styles.field} placeholder={i18n('Segment Height (SH)')} label={i18n('Segment Height (SH)')} onBlur={onBlur} onChange={onChange} type="number" value={value} error={error?.message} />
              )}
            />
            <Controller
              name={`left.additionalPower`}
              defaultValue={DEFAULT_VALUES.additionalPower}
              render={({ field: { value, onChange } }) => (
                <IndexSelector className={styles.field} value={value} onChange={onChange} label={i18n('Additional Power (ADD)')} options={ADDITIONAL_POWER} title={i18n('Select Axis (AXIS)')} />
              )}
            />
          </div>
        </div>
        <Controller
          key={'corridor'}
          name={'corridor'}
          rules={{ required: i18n('Please select a corridor') }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SimpleSelect className={cx(styles.corridorField, styles.field)} options={CORRIDOR} name={'CORRIDOR'} label={i18n('Corridor')} error={error?.message} onChange={onChange} value={value} />
          )}
        />
        <Controller
          key={'baseCurve'}
          name={'baseCurve'}
          rules={{ required: i18n('Base curve is required') }}
          render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
            <Input className={cx(styles.corridorField, styles.field)} label={i18n('Base Curve')} error={error?.message} onBlur={onBlur} onChange={onChange} value={value} />
          )}
        />

        <div className={styles.buttonWrapper}>
          <Button loading={false} type="submit" className={cx(styles.submit)}>{i18n('Save')}</Button>
          <Button loading={false} type="button" className={cx(styles.submit)} theme='secondary' onClick={_ => navigate(location.pathname.replace('extraData/add', ''))}>{i18n('Cancel')}</Button>
        </div>
      </form>
    </FormProvider>
  )
}

AddExtraData.propTypes = {
  customer: PropTypes.object
}

export default AddExtraData
