import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './List.module.scss'
import { useNavigate, useParams } from 'react-router'
import { InfoCard } from 'components'
import useI18n from 'common-hooks/useI18n'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { selectDefaults, selectStores } from 'appSlice'
import { LENS_TYPE_MAPPINGS } from 'components/prescription/prescription-constants';

const ARROW_DOWN = <svg width="12" height="7" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M0.528758 0.528758C0.789108 0.268409 1.21122 0.268409 1.47157 0.528758L5.00016 4.05735L8.52876 0.528758C8.78911 0.268409 9.21122 0.268409 9.47157 0.528758C9.73192 0.789108 9.73192 1.21122 9.47157 1.47157L5.47157 5.47157C5.21122 5.73192 4.78911 5.73192 4.52876 5.47157L0.528758 1.47157C0.268409 1.21122 0.268409 0.789108 0.528758 0.528758Z" fill="#31395F" /> </svg>
const ARROW_UP = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10.7402 6.2207L5.75977 1.24023L0.779297 6.2207" stroke="#31395F" strokeOpacity="0.5" strokeWidth="1.40625" strokeLinecap="round" strokeLinejoin="round" /> </svg>


function ExtraDataList({ email, items, customer }) {
  const navigate = useNavigate();
  const { i18n } = useI18n();
  const { id } = useParams();
  const stores = useSelector(selectStores);
  const { defaultWarehouse } = useSelector(selectDefaults);

  const goToAdd = () => {
    navigate(`/customers/${email}/prescriptions/extraData/add`)
  }

  function getIndexToInsertSelected() {
    if (!id) return 0;
    const n = items.findIndex(x => Number(x.id) === Number(id)) + 1;
    if (n === -1) return 0;
    if (n % 3 === 0) return n;
    return n + (3 - (n % 3));
  }

  const handleClick = _id => {
    let endpoint = `/${_id}`;
    if (Number(id) === Number(_id)) {
      endpoint = '';
    }

    if (endpoint) {
      navigate(`/customers/${email}/prescriptions/extraData${endpoint}`)
    } else {
      navigate(`/customers/${email}/prescriptions`)
    }
  }

  const renderSelectedItem = () => {
    const item = items.find(x => Number(x.id) === Number(id));

    return (
      <div key={`${id}-selected`} className={cx(styles['selected-item'])}>
        <div className={cx(styles.commentAndPrintWrapper)}>
          {<p className={cx(styles.comment)}>
            <span className={cx(styles['comment-title'])}>{i18n('Right eye (OD) SH')}</span>
            <span className={cx(styles['comment-body'])}>{item.right.segmentHeight}</span>
          </p>}
          {<p className={cx(styles.comment)}>
            <span className={cx(styles['comment-title'])}>{i18n('Right eye (OD) ADD')}</span>
            <span className={cx(styles['comment-body'])}>{item.right.additionalPower}</span>
          </p>}
        </div>
        <div className={cx(styles.commentAndPrintWrapper)}>
          {<p className={cx(styles.comment)}>
            <span className={cx(styles['comment-title'])}>{i18n('Left eye (OD) SH')}</span>
            <span className={cx(styles['comment-body'])}>{item.left.segmentHeight}</span>
          </p>}
          {<p className={cx(styles.comment)}>
            <span className={cx(styles['comment-title'])}>{i18n('Left eye (OD) ADD')}</span>
            <span className={cx(styles['comment-body'])}>{item.left.additionalPower}</span>
          </p>}
        </div>
        <div className={cx(styles.commentAndPrintWrapper)}>
       
          {<p className={cx(styles.comment)}>
            <span className={cx(styles['comment-title'])}>{i18n('Base Curve')}</span>
            <span className={cx(styles['comment-body'])}>{item.baseCurve}</span>
          </p>}
          {<p className={cx(styles.comment)}>
            <span className={cx(styles['comment-title'])}>{i18n('Corridor')}</span>
            <span className={cx(styles['comment-body'])}>{item.corridor}</span>
          </p>}
          {<p className={cx(styles.comment)}>
            <span className={cx(styles['comment-title'])}>{i18n('SKU')}</span>
            <span className={cx(styles['comment-body'])}>{item.sku}</span>
          </p>}
        </div>
      </div>
    )
  }

  const renderItems = () => {
    const indexToInsertSelected = getIndexToInsertSelected();
    const nodes = items.map(item => (
      <li key={item.id} className={cx(styles.item, { [cx(styles.selected)]: Number(item.id) === Number(id) })} onClick={() => handleClick(item.id)}>
        <div className={cx(styles.header)}>
          <div className={cx(styles['name-date-wrapper'])}>
            <h2 className={cx(styles.name)}>{[item.id, item.name, item.relationshipType].filter(Boolean).join(' | ')}</h2>
          </div>
          {Number(item.id) === Number(id) ? ARROW_UP : ARROW_DOWN}
        </div>
        <div className={cx(styles.content)}>
          <h3 className={cx(styles.lens)}>{item.lensType}</h3>
          <p className={cx(styles.staff)}>{'SKU: ' + item.sku}</p>
          <p className={cx(styles.staff)}>{'Corridor: ' + item.corridor}</p>
          <p className={cx(styles.staff)}>{'Base Curve: ' + item.baseCurve}</p>
        </div>
      </li>
    ));

    if (indexToInsertSelected) nodes.splice(indexToInsertSelected, 0, renderSelectedItem());
    return nodes;
  }

  return (
    <InfoCard title={i18n('Extra Data')} cta={i18n('Add')} onCtaClick={goToAdd}>
      {items?.length ? (
        <ul className={cx(styles.items)}>{renderItems()}</ul>
      ) : <>
        <p>{i18n('No Extras ')}</p>
        <p>{i18n('-')}</p>
      </>
      }
    </InfoCard>
  )
}

ExtraDataList.defaultProps = {
  items: []
}

ExtraDataList.propTypes = {
  email: PropTypes.string,
  items: PropTypes.array
}

export default ExtraDataList
